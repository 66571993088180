import { Link } from "gatsby";
import React from "react";
import Layout from "../../../other/layout";
import SEO from "../../../other/seo";
import { PREVIEW } from "../../../project-config";
import guarantee from "../../../assets/img/guarantee.gif";
export default function DealerPoliciesPage() {
  return (
    <Layout>
      <SEO title="Privacy Policy" />

      <div class="staticContent privacy">
        <h1>Privacy Policy</h1>
        <p>
          DFS is the reseller division of New England Business Services, a
          business unit of Deluxe Small Business Sales, Inc. DFS offers private
          label business products at wholesale prices for resale by qualified
          dealers. DFS maintains a firm commitment to your privacy. In that
          effort, we respect and protect the privacy of those who use our Web
          site, products, and services.
        </p>
        <p>
          This Privacy Policy, effective June 5, 2019, applies to all personal
          information we obtain. We have a long history of recognizing and
          protecting the privacy of our dealers' information. We strive to
          protect your privacy and utilize technology that gives you a safe and
          powerful online experience. We want to assure you that when we ask for
          customer information, our goal is to improve our dealer relationships.
        </p>
        <br />
        <h2>Guiding Principles of Privacy</h2> <br />
        <div>
          <h3>Accuracy:</h3> A commitment to use reasonable procedures to ensure
          that information collected, used, and exchanged will be accurate and
          complete.
        </div>
        <br />
        <div>
          <h3>Security:</h3> A commitment to use appropriate security measures
          for protecting information from unauthorized access, use, and
          disclosure.
        </div>
        <br />
        <div>
          <h3>Compliance:</h3> A commitment to collect, use, and exchange data
          in accordance with applicable laws, and contractual obligations.
        </div>
        <br />
        <div>
          <h3>Confedentiality:</h3> A commitment to collect, use, and exchange
          information in accordance with appropriate confidentiality standards.{" "}
        </div>
        <br />
        <div>
          <h3>Privacy:</h3> A commitment to self-regulation in recognition of
          expectations of privacy, our policy, and in accordance with leading
          industry standards.
        </div>
        <br />
        <div>
          <h3>Responsiveness:</h3> A commitment to respond to privacy-related
          inquiries in a timely and accurate manner.{" "}
        </div>
        <br />
        <div>
          <h3>Employee Education:</h3> A commitment to educate employees
          regarding information practices and the proper handling and use of
          information.
        </div>
        <br />
        <div>
          <h3>Self-Auditing:</h3> A commitment to periodic auditing of internal
          activities to ensure adherence to established information practices,
          policies, and procedures.
          <p>
            The following statements will help you understand how we collect,
            use, and safeguard the information you provide to us. It also
            describes your individual access rights to your personal
            information.
          </p>
        </div>
        <br />
        <h2>Personal Information You Choose to Provide</h2>
        <p>
          At times, we may request that you voluntarily supply personal
          information for purposes such as acquiring our products or services,
          requesting information or product samples registering on this website,
          or making purchases. If you elect to participate, we may require
          personal information, including your name, mailing address, phone
          number, bank information, credit card information or email address.
          When you submit personal information to us, you understand and agree
          that we may access, store, and use your personal information.
          Information you provide will be protected according to our strict
          standards of security and confidentiality.
        </p>
        <br />
        <h2>Cookie Definition and Use</h2>
        <p>
          Cookies are small pieces of information that are stored by your Web
          browser on your computer's hard drive. A cookie may contain
          information (such as a unique user session ID) that is used to track
          the pages of the Web sites you've visited. This Web site uses cookies
          to improve your shopping experience. The information stored in cookies
          helps us serve you better by keeping track of your order as you shop
          at our sites. This information also helps us improve our site design.
          Most Web browsers automatically accept cookies, but you can set your
          browser to decline cookies. Even if your Web browser is set to decline
          cookies, you can use many of the features within this site. However,
          you will not be able to place an order or retrieve your order status.
        </p>
        <br />
        <h2>How Collected Information is Used</h2>
        <p>
          We are committed to protecting your personal information. We use the
          information collected about you to provide the services that you’ve
          requested, process orders, and to provide a more personalized shopping
          experience. We may also use your information to communicate with you
          about products, services, and future promotions.
        </p>
        <p>
          If you do not want to receive correspondence from us, you may opt out
          as described below.
        </p>
        <br />
        <h2>How Collected Information is Shared</h2>
        <p>
          We may share your information with business partners and other third
          parties in order to allow them to provide you with offers, promotions,
          ads or products, and services that we think you may enjoy. If you do
          not want us to share your personal information, you may opt out as
          described below.
        </p>
        <p>
          We may also share your information with third parties who provide
          services on our behalf to help with our business activities. These
          companies are authorized to use your personal information only as
          necessary to provide these services to us, pursuant to written
          instructions. In such cases, these companies must abide by our data
          privacy and security requirements and are not allowed to use personal
          information they receive from us for any other purpose. Representative
          business processes that our service providers/vendors assist us with
          include: shipping/mailing fulfillment.
        </p>
        <p>
          In certain situations, we may be required to disclose personal data in
          response to lawful requests by public authorities, including to meet
          national security or law enforcement requirements. We may also
          disclose your personal information as required by law, such as to
          comply with a subpoena or other legal process, when we believe in good
          faith that disclosure is necessary to protect our rights, protect your
          safety or the safety of others, investigate fraud, or respond to a
          government request.
        </p>
        <br />
        <h2>Security of Information</h2>
        <p>
          Security measures are in place on this Web site to protect against the
          loss, misuse, and alteration of the information under our control. We
          offer industry-standard security measures. When you are on this Web
          site, the information you provide is encrypted and scrambled en route
          and decoded once it reaches us. Please remember that e-mail, which is
          different from the encrypted sessions above, is not secure. Therefore,
          we ask that you do not send confidential information such as social
          security or account numbers to us via an unsecured e-mail message.
          Send confidential information to us via U.S. mail or phone.
        </p>
        <p>
          We take steps to require that our vendors, consultants, suppliers, and
          contractors observe our Privacy Policy with respect to security and
          the collection, use, and exchange of our customers' information. They
          are expected to abide by the Privacy Policy when conducting work for
          us.
        </p>
        <br />
        <h2>Choice to Opt Out</h2>
        <p>
          If you wish to opt out of 1) receiving unsolicited marketing mail as a
          result of your transaction with us or 2) authorizing us to share your
          personal information with unaffiliated third parties and other
          organizations. Please see Contacting DFS below for contact
          information.
        </p>
        <br />
        <h2>Correct/Update/Access</h2>
        <p>
          We are committed to ensuring that the information we obtain and use
          about our customers is accurate. You can help us maintain accurate
          records by informing us of changes or modifications to your personal
          information using one of the methods listed under Contacting DFS. Our
          service representatives are trained to answer questions about and give
          you reasonable access to your personal information in our database.
        </p>
        <br />
        <h2>Target Audience</h2>
        <p>This web site is intended for Canadian residents only.</p>
        <p>
          This Web site is directed at prospective and active distributors. We
          do not knowingly collect or use information from children. Should
          children access this Web site, we are confident that parents will not
          judge any of the information provided as objectionable for viewing. If
          you are under 18, you may use this Web site only with the involvement
          of a parent or guardian.
        </p>
        <br /> <h2>Other Web Sites Linked to the DFS Web Site</h2>
        <p>
          We may provide links within our sites and services to the sites or
          services of third parties. We are not responsible for the collection,
          use, monitoring, storage or sharing of any personal information by
          such third parties, and we encourage you to review those third
          parties' privacy notices and ask them questions about their privacy
          practices as they relate to you.
        </p>
        <br />
        <h2>Enforcement</h2>
        <p>
          Periodically, our operations and business practices are reviewed for
          compliance with corporate policies and procedures governing the
          confidentiality of information. These reviews are conducted by
          internal staff, internal auditors who report directly to our parent
          company Board of Directors, external auditing and accounting firms,
          and government regulators. Included in these self-assessments and
          examinations are reviews of the controls and safeguards related to
          consumer privacy described in our Privacy Policy.
        </p>
        <br />
        <h2>Privacy Policy Changes and Acceptance of These Terms</h2>
        <p>
          We may update this Privacy Notice and its effective date to reflect
          changes to our data governance practices. If we propose to make any
          material changes, we will notify you by means of a notice on this page
          prior to the change becoming effective. We encourage you to
          periodically review this page for the latest information on our
          privacy practices. By using this Web site, you consent to our posted
          Privacy Policy. If you do not agree with this Privacy Policy, please
          do not use this Web site.
        </p>
        <br />
        <h2>About Registration</h2>
        <p>
          DFS provides registration as a service to you, our customer. You
          choose whether or not to take advantage of it.
        </p>
        <p>
          If you register with us, we may store your information in an effort to
          make your ordering experience faster and easier. After registering,
          you may log in with your user name and password. Our "auto-login"
          service allows you to automatically access your purchase history and
          order status without having to re-key your user name and password.
        </p>
        <p>Here is what we may store for you and why:</p>
        Your online purchase history: You don't have to remember what or how
        much; it allows you to reorder with just one click. Your purchasing
        information (billing, shipping contact, phone, credit card): Frees you
        from re-keying the information on every order. Your line of business
        and/or accounting software: Gives us the opportunity to offer
        promotions, products, and advice tailored to meet the needs of your
        business.
        <p>
          The information you provide during registration is stored in a secure
          database. You may modify your purchasing information at any time.
        </p>
        <p>
          On the registration form, you may choose to receive various
          communications from us. If at any time, you would like to discontinue
          communications (emails, phone calls, faxes, newsletters or advertising
          from our partners), you may let us know on the registration form.
        </p>
        <br />
        <h2>About Surveys and Contests</h2>
        <p>
          On occasion, DFS may conduct a survey or offer a contest. It will
          always be your decision whether or not to participate. We use the
          information gathered through these methods to help us develop new and
          improved products and market them to you. In addition, the information
          may help us tailor your experience on our site, showing products and
          services that would be helpful to your business.
        </p>
        <br />
        <h2>Contacting DFS</h2>
        <p>
          For correction, update, access, and opt-out requests, please contact
          us at:
        </p>
        Email:
        <a href="mailto:printing@dfsbusiness.com">printing@dfsbusiness.com</a>
        <br />
        Phone: 1-800-490-6327 ( 8am to 6pm EST ) <br />
        Fax: 1-800-255-6327
        <br />
        <p>
          Mail: <br />
          Attn: DFS List Services
          <br />
          330 Cranston Crescent
          <br />
          Midland, ON L4R 4V9
        </p>
      </div>
    </Layout>
  );
}
